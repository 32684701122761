import { Link } from 'gatsby-plugin-intl'
import styled from 'styled-components'

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
  @media all and (max-width: 766px) {
    flex-direction: column;
  }
`

const DownloadButton = styled.a`
  text-decoration: none;
  padding: 0.5rem;
  &:hover,
  &:focus {
    text-decoration: none;
    background: ${props => props.theme.colors.caramel};
  }
  cursor: pointer;
  background: ${props => props.theme.colors.caramel};
  color: ${props => props.theme.colors.cream};
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  p {
    margin: 0;
    flex: 0 0 50%;
    @media all and (max-width: 600px) {
      flex: 0 0 100%;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.cream};
  }
`

const Container = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  padding: 1rem;
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
  font-family: sans-serif;
  text-align: center;
  background-color: ${props => props.theme.colors.cream};
`

const Services = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem;
  box-shadow: 0 30px 40px 0 ${props => props.theme.colors.coffeeWithMilk};
`

const ButtonHolder = styled.div`
  text-align: left;
  padding: 2rem 0 1rem 1rem;
  @media all and (max-width: 360px) {
    text-align: center;
    padding: 1.5rem 0 0 0;
  }
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.cream};
  background: ${props => props.theme.colors.caramel};
  text-decoration: none;
  margin-right: 15px;
  border: 1px solid ${props => props.theme.colors.caramel};
  border-radius: 4px;
  padding: 8px 16px;
  transition: all 0.2s ease;
  @media all and (max-width: 360px) {
    padding: 8px;
    margin-right: 0;
  }
`

export {
  Container,
  Services,
  StyledLink,
  ButtonHolder,
  ToolBar,
  DownloadButton,
}
