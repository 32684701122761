import {FormattedMessage, useIntl} from 'gatsby-plugin-intl'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {DownloadIcon} from '../components/icons'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Service from '../components/service'
import {StyledParagraph, Subtitle} from '../styles/index'
import {ButtonHolder, Container, DownloadButton, Services, StyledLink, ToolBar,} from '../styles/schedule'
import {filteredByMonth, filteredByNextMonth, isInTheSameMonth,} from '../utils/helpers'
import {fetchSchedules, fetchServices} from '../data/contentful'
import {GlobalDispatchContext, GlobalStateContext,} from '../context/GlobalContextProvider'

const Schedulepage = () => {
    const intl = useIntl()
    const globalState = useRef(useContext(GlobalStateContext))
    const [services, setServices] = useState(globalState.current.services)
    const [schedules, setSchedules] = useState([])
    const [assets, setAssets] = useState([])

    const dispatch = useContext(GlobalDispatchContext)

    useEffect(() => {
        if (!globalState.current.services) {
            async function getServices() {
                const services = await fetchServices()
                setServices(services)
                dispatch({type: 'SET_SERVICES', value: services})
            }

            getServices().then((r) => r)
        }
    }, [dispatch])

    useEffect(() => {
        async function getSchedules() {
            const [items, Asset] = await fetchSchedules()
            setSchedules(items)
            setAssets(Asset)
        }

        getSchedules().then((r) => r)
    }, [])

    const renderDownloadButton = () => {
        const filteredSchedules = isInTheSameMonth(services[0])
            ? filteredByMonth(schedules)
            : filteredByNextMonth(schedules)

        const current = filteredSchedules.length > 0 ? filteredSchedules[0] : null

        const asset = current
            ? assets.filter(
                (asset) => asset.sys.id === current.fields[`${intl.locale}`].sys.id,
            )[0]
            : null

        const url = asset ? asset.fields.file.url : null
        const size = asset ? asset.fields.file.details.size : null

        return url && size ? (
            <DownloadButton href={url} download target='_blank' title='PDF'>
                <div>
                    <DownloadIcon size={48} color={'white'}/>
                    <span>{`${Math.ceil(size / 1000)} Kb`}</span>
                </div>
                <p>
                    <FormattedMessage id={`download`}/>
                </p>
            </DownloadButton>
        ) : null
    }

    return (
        <Layout>
            <Seo title={intl.formatMessage({id: 'subtitle_schedule'})}/>
            <Container>
                <Services>
                    <ToolBar>
                        <Subtitle>
                            <FormattedMessage id={'subtitle_schedule'}/>
                        </Subtitle>
                        {services && schedules.length && assets.length
                            ? renderDownloadButton()
                            : null}
                    </ToolBar>
                    {services ? (
                        services.map((service, i) => {
                            const text = service.fields[intl.locale]
                            return <Service key={i} text={text}/>
                        })
                    ) : (
                        <StyledParagraph>
                            {intl.formatMessage({id: 'no_services'})}
                        </StyledParagraph>
                    )}
                    <ButtonHolder>
                        <StyledLink to='/'>
                            {intl.formatMessage({id: 'home_page'})}
                        </StyledLink>
                    </ButtonHolder>
                </Services>
            </Container>
        </Layout>
    )
}

export default Schedulepage
